import { Controller } from "stimulus"
import { EditorState } from "@codemirror/state";
import { EditorView, keymap, lineNumbers, highlightActiveLine } from "@codemirror/view";
import { syntaxHighlighting, defaultHighlightStyle, indentOnInput } from "@codemirror/language";
import { defaultKeymap } from "@codemirror/commands";
import { html } from "@codemirror/lang-html";

export default class extends Controller {
  static targets = ["editor"];

  connect() {
    const textarea = this.editorTarget;

    this.editor = new EditorView({
      defaultKeymap,
      state: EditorState.create({
        doc: textarea.value,
        scrollbarStyle: "simple",
        extensions: [
          highlightActiveLine(),
          lineNumbers(),
          indentOnInput(),
          syntaxHighlighting(defaultHighlightStyle), 
          html(),
          keymap.of(defaultKeymap),
          EditorView.updateListener.of((view) => {
            if (view.docChanged) { 
              this.sync() 
            }
          }),
        ],
      }),
      parent: textarea.parentNode, 
    });

    textarea.style.display = "none";
  }

  disconnect() {
    this.editor.destroy();
  }

  sync() {
    const value = this.editor.state.doc.toString();
    this.editorTarget.value = value;
    this.editorTarget.innerHTML = value;
  }
}


